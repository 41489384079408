import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonCheckbox,
  IonItem,
  IonList,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  IonAlert,
  IonTextarea,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import Layout from '../../components/layout';
import Incrementer from '../../components/incrementer';
import Pullup from '../../components/pullup';
import {
  Title,
  Sectiontitle,
  SmallText,
  Spacer,
  FlexSpacer,
  Hr,
  NormalText,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import {
  makeKey,
  isDefined,
  deepCopy,
  goBack,
  isWebConfig,
  forwardTo,
  isEmptyObject,
} from '../../lib/utils';
import { getLocale } from '../../lib/utils';
import { PillGroup } from '../../components/pill';
import { storeItemWeb } from '../../store/actions';
import { getConfig } from '../../appConfig';
import './index.css';
import Modal from '../../components/modal';

const {
  formatPrice,
  _calculateItemPrice,
  validateItem,
  addToBasket,
  getProductName,
  getProductDescription,
  isProductEnabled,
  isProductJustEnabled,
  isChoicesGroupValid,
  setAllergen,
} = Basket;

const noImage = () => <div className="image-placeholder item-details-img"></div>;

class itemDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      quantity: 1,
      price: 0,
      selectedChoices: [],
      validationErrors: [],
      showValidationAlert: false,
      allergensCodes: [],
      instructions: '',
      modalInstructions: '',
      specialInstructionsModalOpen: false,
    };
  }
  componentDidMount() {
    const { profile, allergens, item } = this.props;
    let quantity = this.state.quantity;
    let selectedChoices = [];
    let validationErrors = [];

    if (item.menuDealGroups && item.menuDealGroups.length > 0) {
      selectedChoices = Array(item.menuDealGroups.length).fill([]);
      validationErrors = Array(item.menuDealGroups.length).fill(null);
    }
    let price = _calculateItemPrice({ item, quantity });
    let locale = getLocale(profile);
    if (locale === 'fr') {
      locale = 'fr_FR';
    }
    let allergensCodes = item
      ? item.itemRichData
        ? (item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length) > 0
          ? item.itemRichData.allergenCodes
          : []
        : []
      : [];
    let newArr = [];
    allergensCodes.map((allergenCode) => {
      let dataArr = (allergens && allergens.data ? allergens.data : []).find((allergen) => {
        let dataArr2 = null;
        if (allergen.code === allergenCode) {
          dataArr2 = allergen.translations.find((data) => {
            if (data.locale === locale) {
              newArr.push(data.text);
              return data;
            }
            return null;
          });
        }
        return dataArr2;
      });
      if (!dataArr) {
        newArr.push(allergenCode);
      }
      return null;
    });

    this.setState({ price, selectedChoices, validationErrors, allergensCodes: newArr }, () => {
      const initialValidationErrors = validateItem(this.constructBasketItem());
      this.setState({ validationErrors: initialValidationErrors.errors });
    });
  }

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantity: newQuantity,
      price: _calculateItemPrice({
        item: this.props.item,
        quantity: newQuantity,
        selectedChoices: this.state.selectedChoices,
      }),
    });
  };

  updateSelectedChoices = (
    groupIndex,
    choiceIndex,
    multiSelectionPermitted,
    selected = true,
    quantity = 0,
  ) => {
    const { item } = this.props;
    const { selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex]
        ? deepCopy(selectedGroup.items[choiceIndex])
        : {};
      let updatedSelectedChoices = deepCopy(selectedChoices);
      let selectedChoiceIndex = -1;
      const existingChoice = updatedSelectedChoices[groupIndex].find((i, index) => {
        if (selectedChoice && i.sku === selectedChoice.sku) {
          selectedChoiceIndex = index;
          return true;
        }
        return false;
      });
      if (multiSelectionPermitted) {
        //checkbox
        if (selected) {
          selectedChoice.quantity = 1;
          if (existingChoice) {
            existingChoice.quantity = quantity;
            if (
              selectedChoiceIndex !== -1 &&
              updatedSelectedChoices[groupIndex] &&
              updatedSelectedChoices[groupIndex][selectedChoiceIndex]
            ) {
              updatedSelectedChoices[groupIndex][selectedChoiceIndex] = existingChoice;
            }
          } else {
            updatedSelectedChoices[groupIndex].push(selectedChoice);
          }
        } else {
          selectedChoice.quantity = 0;
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        if (selected) {
          selectedChoice.quantity = quantity;
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        } else {
          selectedChoice.quantity = 0;
          updatedSelectedChoices[groupIndex] = [];
        }
      }

      this.setState({ selectedChoices: updatedSelectedChoices }, () => {
        const { selectedChoices } = this.state;
        const newCalculatedPrice = _calculateItemPrice({
          item,
          quantity: this.state.quantity,
          selectedChoices,
        });
        this.setState(
          { price: _calculateItemPrice({ item, quantity: this.state.quantity, selectedChoices }) },
          () => {
            if (this.props.priceUpdated) {
              this.props.priceUpdated(newCalculatedPrice);
            }
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          },
        );
      });
    }
  };
  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.props.item;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      this.updateSelectedChoices(
        groupIndex,
        choiceIndex,
        multiSelectionPermitted,
        multiSelectionPermitted ? event.target.checked : !!selectedChoice,
        selectedChoice ? 1 : 0,
      );
    }
  };

  onIncrementerChoiceUpdate = (newQuantity, data) => {
    const { item } = this.props;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[data.groupIndex];
    if (selectedGroup) {
      this.updateSelectedChoices(
        data.groupIndex,
        data.choiceIndex,
        data.multiSelectionPermitted,
        newQuantity > 0,
        newQuantity,
      );
    }
  };
  drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
    const { items, max, multiMax } = menuGroupItem;
    const choices = items || [];
    const { selectedChoices } = this.state;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
        return i.sku === sku;
      });
      if (item.sku === undefined || !isProductJustEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      const groupQuantity =
        selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0
          ? selectedChoices[groupIndex].reduce((sum, i) => sum + i.quantity || 0, 0)
          : 0;
      let quantity = 0;
      if (selectedChoices[groupIndex]) {
        const _choice = selectedChoices[groupIndex].find((i) => i.sku === sku);
        quantity = _choice ? _choice.quantity : 0;
      }
      let groupQunatity = 0;
      (selectedChoices[groupIndex] || []).forEach((i) => {
        groupQunatity += isDefined(i.quantity) ? i.quantity : 1;
      });
      const shouldBeDisabled =
        isDefined(max) &&
        max > 0 &&
        isDefined(selectedChoices[groupIndex]) &&
        groupQunatity >= max &&
        !isChecked;
      if (menuGroupItem.hasOwnProperty('multiMax')) {
        return (
          <div
            className={`sub-item ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
            key={makeKey(choiceIndex, sku, groupIndex)}
          >
            <IonItem tabIndex="-1" lines="none">
              {multiSelectionPermitted ? (
                <IonCheckbox
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                  className="details"
                  color="primary"
                  slot="start"
                  checked={isChecked}
                  onIonChange={(event) => {
                    Basket.isProductUnsnoozed(item) &&
                      this.handleInputChange(
                        groupIndex,
                        choiceIndex,
                        multiSelectionPermitted,
                        event,
                      );
                  }}
                />
              ) : (
                <IonRadio
                  slot="start"
                  className={'details' + (isChecked ? ' primary-radio' : '')}
                  color="primary"
                  value={choiceIndex}
                  checked={isChecked}
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                />
              )}
              <div className="ion-text-wrap up">
                <NormalText className=" item-details-product-label block">
                  {__(getProductName(item, profile))}{' '}
                  {formatPrice(item.productPrice) ? (
                    <strong className="block">{formatPrice(item.productPrice)}</strong>
                  ) : null}
                </NormalText>
                {getProductDescription(item, profile) ? (
                  <SmallText className="no-margin item-description">
                    {getProductDescription(item, profile)}
                  </SmallText>
                ) : null}
                {isDefined(item.itemRichData) &&
                isDefined(item.itemRichData.allergenCodes) &&
                item.itemRichData.allergenCodes.length > 0 ? (
                  <>
                    <Spacer size="1" />
                    <SmallText>{__('Allergens')}</SmallText>
                    <PillGroup items={item.itemRichData.allergenCodes} borderColor="primary" />
                  </>
                ) : null}
              </div>
            </IonItem>
            <div className="price-increment-content">
              {quantity >= 1 && max > 1 && multiMax > 1 ? (
                <div
                  className={formatPrice(productPrice) ? 'incrementer-box' : 'incrementer-box-top'}
                >
                  <Incrementer
                    allowNegative={false}
                    data={{ groupIndex, choiceIndex, multiSelectionPermitted }}
                    disabled={shouldBeDisabled}
                    maxLimit={Math.min(multiMax, (quantity || 0) + max - groupQuantity)}
                    onUpdate={this.onIncrementerChoiceUpdate}
                    quantity={quantity || 0}
                    color='secondary'
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      } else {
        return (
          <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
            <div tabIndex="-1"></div>
            {multiSelectionPermitted ? (
              <IonCheckbox
                color="secondary"
                slot="start"
                checked={isChecked}
                onIonChange={(event) => {
                  this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
                }}
              />
            ) : (
              <IonRadio
                slot="start"
                className="details-radio"
                color="primary"
                value={choiceIndex}
                checked={isChecked}
              />
            )}
            <IonLabel className="ion-text-wrap">
              <NormalText className=" item-details-product-label block">
                {__(getProductName(item, profile))}{' '}
                {formatPrice(item.productPrice) ? (
                  <strong className="block">{formatPrice(item.productPrice)}</strong>
                ) : null}
              </NormalText>
              {getProductDescription(item, profile) ? (
                <div>
                  <SmallText
                    className="no-margin item-details-card-description"
                    dangerouslySetInnerHTML={{
                      __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                    }}
                  ></SmallText>
                </div>
              ) : null}
            </IonLabel>
            {/* <p>{formatPrice(productPrice)}</p> */}
          </IonItem>
        );
      }
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          allowEmptySelection={true}
          value={
            selectedChoices && selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0
              ? choices.findIndex((i) => i.sku === selectedChoices[groupIndex][0].sku)
              : -1
          }
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (menuGroupItem, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;
    const { description, min, max } = menuGroupItem;
    const itemDontHaveMinMax = (!isDefined(min) && !isDefined(max)) || (min === 0 && max === 0);
    let minMaxlabel = null;

    if (isDefined(min) && isDefined(max)) {
      if ((min === 0 && max === 0) || (min === 0 && max === -1)) {
        minMaxlabel = null;
      }
      if (min === 0 && max > 0) {
        minMaxlabel =
          __('Select up to') +
          ' ' +
          max +
          ' ' +
          (max === 1 ? __('item (optional)') : __('items (optional)'));
      }
      if (min > 0 && min === max) {
        minMaxlabel = __('Select') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if ((min > 0 && max === 0) || max === -1) {
        minMaxlabel =
          __('Select at least') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if (min > 0 && max > 0 && min !== max) {
        minMaxlabel =
          __('Select between') + ' ' + min + ' ' + __('and') + ' ' + max + ' ' + __('options');
      }
    } else {
      minMaxlabel = null;
    }

    return (
      <>
        <IonItem>
          <div tabIndex="-1" className="sectiontitle">
            <NormalText>
              <strong>{description}</strong>
            </NormalText>

            {itemDontHaveMinMax ? null : isDefined(minMaxlabel) && minMaxlabel !== '' ? (
              <>
                <br />
                <SmallText className={validationErrors[groupIndex] ? 'field-error' : 'hidden'}>
                  {minMaxlabel}
                </SmallText>
              </>
            ) : null}
          </div>
        </IonItem>
        {itemDontHaveMinMax && validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index} className="menu-deal-group-wrapper">
            {this.drawGroupLabel(menuGroupItem, index)}
            {this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem);
      }
    }
  };

  constructBasketItem = () => {
    const { item } = this.props;
    const { quantity, selectedChoices, instructions } = this.state;
    // const item = item

    return {
      item,
      quantity,
      selectedChoices,
      instructions,
    };
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    const { item } = this.props;
    // const item = this.props.location.state
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      // addToBasket(newBasketItem)
      // forwardTo('/order-summary', { fromItemDetails: true })
      // goBack()
      if (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)) {
        this.props.dispatch(storeItemWeb(newBasketItem));
        forwardTo('/delivery-options');
        // forwardTo('/click-and-collect');
      } else {
        addToBasket(newBasketItem);
        if (isWebConfig()) {
          this.props.closeModal();
        } else {
          goBack();
        }
      }
    }

    let allergensCodes = item.itemRichData
      ? item.itemRichData.allergenCodes
        ? item.itemRichData.allergenCodes
        : []
      : [];
    if (allergensCodes.length > 0) {
      let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
      if (isDefined(newBasketItem.selectedChoices)) {
        newBasketItem.selectedChoices.forEach((group) => {
          group.forEach((item) => {
            if (
              isDefined(item.itemRichData) &&
              isDefined(item.itemRichData.allergenCodes) &&
              item.itemRichData.allergenCodes.length > 0
            ) {
              setAllergen([{ allergens: item.itemRichData.allergenCodes }, { sku: item.sku }]);
            }
          });
        });
      }
      setAllergen(allergensData);
    }
  };

  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  render() {
    const { __, profile, item } = this.props;
    const { quantity, showValidationAlert, allergensCodes } = this.state;
    const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;
    return (
      <>
      <div className="item-details-card-content">
        <IonList className="item-details-card-list">
          {getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '') && (
            <IonItem lines="none">
              <div tabIndex="-1"></div>
              <SmallText
                color="primary"
                className="item-details-card-description"
                dangerouslySetInnerHTML={{
                  __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                }}
              ></SmallText>
            </IonItem>
          )}
          {allergensCodes.length > 0 ? (
            <>
              <Spacer size="1" />
              <Sectiontitle className="allergens-title">
                <strong>{__('Allergens')}</strong>
              </Sectiontitle>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <PillGroup items={allergensCodes} borderColor="primary" paddingTop={4} />
              </IonItem>
            </>
          ) : null}
          {menuDealGroups.map(this.drawMenuDealGroups)}
          {getConfig().flags.specialInstructions.isDisplayed ? (
            <>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <Sectiontitle>
                  {__('Special Instructions')}{' '}
                  <span className="normal-text">{__('Optional')}</span>
                </Sectiontitle>
              </IonItem>
              <IonItem>
                <IonTextarea
                  onIonChange={this.instructionsChange}
                  rows={1}
                  placeholder={__(getConfig().flags.specialInstructions.placeholder)}
                ></IonTextarea>
              </IonItem>
            </>
          ) : null}
        </IonList>
      </div>
      <div className="item-details-actions">
        <Incrementer
          allowNegative={false}
          quantity={quantity}
          onUpdate={this.onIncrementerUpdate}
        />
        <IonButton
          disabled={quantity === 0 || !isAddToOrderBtnValid}
          className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'greyed')}
          size="full"
          shape="round"
          color="secondary"
          onClick={this.addToOrder}
        >
          {Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
            ? __('Start New Order')
            : __('Add to Order')}{' '}
        </IonButton>
      </div>
      <IonAlert
        isOpen={showValidationAlert}
        onDidDismiss={() => this.setShowValidationAlert(false)}
        header={__('Validation')}
        message={__('Please check any required options')}
        buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
      />
    </>
  );
}
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
    allergens: store.restaurants.allergens,
    storedItemWeb: store.orders.storedItemWeb,
    ikentooMenu: store.restaurants.ikentooMenu || {},
  };
};

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

class itemDetails extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      nameVisible: true,
    };
  }
  onContentScrollHandler() {
    if (!this.isScrolledIntoView(this.x.current)) {
      this.setState({ nameVisible: false });
    } else {
      this.setState({ nameVisible: true });
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (this.state.nameVisible !== prevState.nameVisible) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { location, __, profile } = this.props;
    const item = location.state;
    const { selectedMenu } = item;
    let image =
      item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
    if (image && image.indexOf('http://') !== -1) {
      image = image.replace(/http:\/\//g, 'https://');
    }

    return (
      <Layout
        headerTitle={__('Item Details')}
        scrollY={false}
        color="transparent"
        noPadding
        contentClassName="item-details-wrapper"
      >
        <div className="item-details-main-wrapper">
          <div
            className={
              this.state.nameVisible ? 'item-details-header' : 'active item-details-header'
            }
          >
            <div className="item-details-back">
              <IonButton
                className="solo-button"
                color="white"
                onClick={() => forwardTo('/order', { selectedMenu })}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </div>
            <div
              style={{
                transition: !this.state.nameVisible ? 'transform 0.3s ease-in-out' : 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
                transform: this.state.nameVisible ? 'translateY(-200%)' : 'translateY(0)',
              }}
            >
              <Title >
                <strong>{item.productName}</strong>
              </Title>
            </div>
          </div>
          <div
            className="scrollable-y"
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div className="item-details-info">
              {image && image !== '' ? (
                <div className="item-details-img">
                  <img src={image} />
                </div>
              ) : (
                noImage()
              )}
              <div className="item-details-name" ref={this.x}>
                <Title>{item.productName}</Title>
              </div>
              <div className="item-details-price">
                <NormalText>{formatPrice(item.productPrice)}</NormalText>
              </div>
            </div>

            <ItemDetailsRaw item={item} profile={profile} />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(itemDetails));
