import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { SmallText } from '../../components/common';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import NoDataSecond from '../../components/noDataSecond';
import noDataImage from '../../assets/images/logo/logo-main.png';
import { forwardTo } from '../../lib/utils';
import { connect } from 'react-redux';
import { showToast } from '../../store/actions';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, restaurants, dispatch, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      }
    };
    const orderAgain = (past_orders, option) => {
      const selectedRestaurant = restaurants.filter(
        (restaurant) => restaurant.id == past_orders.restaurant_id,
      );
      if (selectedRestaurant.length > 0) {
        if (option.id == 'collection') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'collection');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_collection_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'table') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'table');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_table_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'charter-delivery') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'charter-delivery');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_charter_delivery_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
      } else {
        dispatch(showToast('This restaurant is no longer accepting orders.', 'warning'));
      }
    };
    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            type === 'order' ? (
              <NoDataSecond label="Your order History will appear here" />
            ) : (
              <NoDataSecond
                label="Your loyalty history will appear here"
                noDataImage={noDataImage}
              />
            )
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (getConfig().delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                let status = item && item.status ? item.status : '';
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow
                    key={index}
                    className={`history-item${item && item?.status === 'NEW' ? '-is-hidden' : ''}`}
                  >
                    <IonCol
                      onClick={() => handleRowClick(item)}
                      className="history-details-description"
                    >
                      {type === 'order' ? (
                        <div className="order-button-wrapper">
                          <div className="order-button bordered-primary">
                            {__(item && item.status ? item.status : '')}
                          </div>
                        </div>
                      ) : null}

                      <SmallText
                        color="primary"
                        className={`${type === 'order' && 'order-location-name'}`}
                      >
                        {type === 'order' ? labelLocationName : location_name}
                      </SmallText>
                      <h2>
                        {type === 'order' ? (option ? option.label + orderId : '') : __(label)}
                      </h2>
                      <SmallText color="primary">
                        <span className="capitalize">
                          {type === 'order' ? option && __(`${option.id}:`) : ''}
                        </span>{' '}
                        {Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                      </SmallText>
                    </IonCol>
                    {type === 'order' ? (
                      <IonCol style={{ flex: '0 1 0', alignSelf: 'center' }}>
                        <IonButton
                          className="customized-button order-again-button"
                          onClick={() => orderAgain(item, option)}
                          color="secondary"
                        >
                          {__('Order Again')}
                        </IonButton>
                      </IonCol>
                    ) : (
                      <IonCol style={{ alignSelf: 'center' }}>
                        <p className={stamp_power < 0 ? 'redeem' : ''}>
                          <img
                            src={stamp_power < 0 ? redeemedArrow : earnedArrow}
                            alt="point-arrows"
                          />
                          {stamp_power < 0 ? '' : '+'}
                          {stamp_power} {__('points')}
                        </p>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);
const stateToProps = (store) => {
  return {
    ikentooMenu: store.restaurants.ikentooMenu || {},
    restaurants: store.restaurants.restaurants || [],
  };
};

export default connect(stateToProps)(withTranslation(HistoryTab));
