import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
// import Order from './screens/order'
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import Idealpay from './screens/idealpay';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import CreateAccount from './screens/createAccount';
import { ServiceCharge } from './screens/service-charge';
import api from './lib/api';

const home = require('./assets/images/icons/home.svg');
const loyalty = require('./assets/images/icons/loyalty.svg');
const myAccount = require('./assets/images/icons/my-account.svg');
const feedback = require('./assets/images/icons/feedback.svg');
const history = require('./assets/images/icons/history.svg');
const restaurants = require('./assets/images/icons/locations.svg');
const social = require('./assets/images/icons/social.svg');
const login = require('./assets/images/icons/login.svg');
const logout = require('./assets/images/icons/logout.svg');
const settings = require('./assets/images/icons/menu.svg');
const referAFriend = require('./assets/images/icons/refer-a-friend.svg');
const vocuher = require('./assets/images/icons/vouchers.svg');
const terms = require('./assets/images/icons/terms.svg');
const privacy = require('./assets/images/icons/privacy.svg');
const faq = require('./assets/images/icons/faq.svg');
const menu = require('./assets/images/icons/menu.svg');

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const { appType } = getConfig();
const { hasCampaignManager, hasOrdering, hasLoyalty, hideTermsAndConditions } = appType;

let navConfig;
export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();
  const singleDeliveryOption = getSingleDeliveryOption();

  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
            label: 'Home',
            path: '/dashboard',
            component: Dashboard,
            icon: home,
            exact: true,
            default: true,
            notInDrawer: true,
          },
      {
        label: 'Menu',
        path: '/order',
        component: Order,
        icon: menu,
        exact: true,
        default: isWebConfig(),
        group: 'button',
      },
      ...(hasOrdering
        ? [
            {
              label: 'Start New Order',
              path: '/delivery-options',
              component: DeliveryOptions,
              icon: settings,
              protected: false,
              notInDrawer: !!singleDeliveryOption,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: settings,
              protected: false,
              notInDrawer: !singleDeliveryOption,
            },
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/order-to-table',
              component: OrderToTable,
              protected: false,
              notInDrawer: true,
            },
          ]
        : []),
      ...(hasLoyalty
        ? [
            {
              label: 'Loyalty',
              path: '/loyalty',
              component: Loyalty,
              icon: loyalty,
              protected: true,
              state: { tab: 'points' },
              group: 1,
            },
          ]
        : []),
      ...(hasCampaignManager
        ? [
            {
              label: 'Vouchers',
              path: '/vouchers',
              component: Vouchers,
              icon: vocuher,
              protected: true,
              group: 1,
            },
          ]
        : []),
      ...(hasCampaignManager
        ? [
            {
              label: 'Refer a Friend',
              path: '/refer-a-friend',
              component: ReferAFriend,
              icon: referAFriend,
              protected: true,
              group: 1,
            },
          ]
        : []),
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
        group: 3,
      },
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              icon: settings,
              notInDrawer: true,
              protected: false,
              default: false,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      {
        label: 'History',
        path: '/history',
        component: History,
        icon: history,
        protected: true,
        group: 3,
      },
      ...(hasOrdering
        ? [
            {
              label: 'Service Charge',
              path: '/service-charge',
              component: ServiceCharge,
              notInDrawer: true,
              protected: false,
            },
          ]
        : []),
      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
        group: 3,
      },
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: restaurants,
        protected: false,
        group: 4,
      },
      {
        label: 'Social Media',
        path: '/social',
        component: Social,
        icon: social,
        protected: false,
        group: 4,
      },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: false,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
    ],
    additionalRoutes: [
      {
        label: 'Terms & Conditions',
        path: '/terms',
        component: Terms,
        icon: terms,
        notInDrawer: hideTermsAndConditions,
      },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacy },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/create-account', component: CreateAccount },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/ideal-pay', component: Idealpay }] : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ],
  };
  return navConfig;
};
export default navConfig;
