import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from '../../lib/stripe';
import IdealBankForm from "../../components/IdealBankForm"
import {Elements} from '@stripe/react-stripe-js';

const CheckoutIdeal = ({ clientSecret, dispatch, __, location, profile }) => {
  const stripePromise = loadStripe(Stripe.getStripeInstance()._apiKey);
  return (
    <Elements stripe={stripePromise}>
      <IdealBankForm 
        clientSecret={clientSecret}
        dispatch={dispatch}
        __={__}
        location={location}
        profile={profile}
      />
    </Elements>
  );
};

export default CheckoutIdeal;
