import React, { useState } from 'react';
import { Title, SmallText } from '../common';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { IonButton,IonInput } from '@ionic/react';
import './index.css';
import Modal from '../modal';


const { getRestauranName, getOrderDate, getOrderTime, getTableNumber } = Basket;

const formatDayName = (name) => {
  if (name.includes('Today')) {
    name = 'Today'.toLowerCase();
  } else if (name.includes('Tomorrow')) {
    name = 'Tomorrow'.toLowerCase();
  } else {
    name = getOrderDate();
  }
  return name;
};

const findSelectedSlot = (dayInWeek, time) => {
  const restaurant = Basket.getRestaurant();
  const selectedDay = restaurant.delivery_times_json.slots[dayInWeek];
  const selectedSlot = selectedDay.find(
    (day) =>
      moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'),
  );
  return (
    moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
    ' - ' +
    moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  );
};

const ContentHeader = ({ __, deliveryOption, orderType, deliveryAddress, pickUpPoint,changeTableNumber }) => {
  let dateName = formatDayName(
    moment(getOrderDate(), 'dddd Do MMM').calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
    }),
  );
  const [changeTableNumberModal, setChangeTableNumberModal] = useState(false);
  const [tableNumber, setTableNumber] = useState(getTableNumber());
  let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd');
  const deliveryLabel =
    deliveryOption.label === 'Order for Delivery' ? 'Delivery Order' : deliveryOption.label;
  const locationCode = pickUpPoint && pickUpPoint.location_code ? pickUpPoint.location_code : '';
  const styledText = { flex: 1 };
  if (!orderType || orderType === '') {
    return null;
  }
  if (orderType === 'pick-up-point') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryOption.label)}</strong>
        </Title>
        <SmallText className="auto-grid">
          <span>
            {__('Ordering for')} {__('drop-off')} {__('at')}{' '}
            <strong>
              {getRestauranName()} {`(#${locationCode})`},{' '}
            </strong>
          </span>{' '}
          <span>
            {dateName} {__('at')} {getOrderTime()}
            <br />
          </span>
        </SmallText>
      </>
    );
  } else if (orderType === 'delivery') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryLabel)}</strong>
        </Title>
        <SmallText className="heading thiner-text auto-grid">
          <span>
            {__('Ordering for')} {__('delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
            {dateName}
          </span>{' '}
          {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */}{' '}
          <span>
            {__('at')} {findSelectedSlot(dayInWeek, getOrderTime())}
            <br />
          </span>
        </SmallText>
      </>
    );
  } else if (orderType === 'Table') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__('Order To Table')}</strong>
        </Title>
        <SmallText >
          {__('Ordering at')} {getRestauranName()}{' '}{__('for')}{' '}
        </SmallText>
        <SmallText className="table-number" onClick={() => setChangeTableNumberModal(true)}>
            {`${__('Table')} ${getTableNumber()}. `}
        </SmallText>
        <SmallText>
          {__('Your order will be brought to you as soon as possible.')}
        </SmallText>
        <Modal
          className={`change-table-number-modal-${changeTableNumberModal}`}
          title={__('Change table number')}
          isOpen={changeTableNumberModal}
          onDidDismiss={() => setChangeTableNumberModal(false)}
        >
          {
            <>
              <IonInput
                className="change-table-number-field"
                value={tableNumber}
                onIonChange={(e) => setTableNumber(e.target.value)}
              />
              <IonButton
                disabled={tableNumber === ''}
                onClick={() => {
                  changeTableNumber(tableNumber);
                  setChangeTableNumberModal(false);
                }}
                expand="block"
                color='secondary'
              >
                {__('Confirm')}
              </IonButton>
            </>
          }
        </Modal>
      </>
    );
  } else if (orderType === 'charter-delivery') {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : ' as soon as possible.';
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryOption.label)}</strong>
        </Title>
        <SmallText /*className="flex-col-wrapper flex-row-holder"*/ className="heading">
          <span>
            {__('Ordering at')} {getRestauranName()} {__('for delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
          </span>
          <span>
            {dateRes}
            <br />
          </span>
          {/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
          {/* { ' ' + __('from')} { getRestauranName()} */}
          {/* <span style={{ marginBottom: 5 }} className="flex-col-wrapper"><strong>{ __('Collection Location') }</strong> <span className="righted thiner-text">{ getRestauranName() }</span></span>
					<span className="flex-col-wrapper"><strong>{ __('Collection Time') }</strong> <span style={ styledText } className="righted thiner-text">{ charterDeliveryDateAndTime !== 'Invalid date' ? `${getOrderDate('D MMMM YYYY')} ${__('at')}  ${getOrderTime('h:mm a')}` : `${Basket.collection_time}`}</span></span> */}
        </SmallText>
      </>
    );
  } else {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(orderType)}</strong>
        </Title>
        <SmallText className="flex-col-wrapper flex-row-holder">
          {/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
          {/* { ' ' + __('from')} { getRestauranName()} */}
          <span style={{ marginBottom: 5 }} className="flex-col-wrapper">
            <strong>{__('Collection Location')}</strong>{' '}
            <span className="righted thiner-text">{getRestauranName()}</span>
          </span>
          <span className="flex-col-wrapper">
            <strong>{__('Collection Time')}</strong>{' '}
            <span style={styledText} className="righted thiner-text">
              {getOrderDate('D MMM YYYY')} {__('at')} {getOrderTime('h:mm a')}
            </span>
          </span>
        </SmallText>
      </>
    );
  }
};

export default ContentHeader;
