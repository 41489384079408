import React from 'react';
import moment from '../../lib/moment';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
// import Box, { BoxHeader } from '../../components/box'
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import AmountPaid from '../../components/amountPaid';
import './index.css';
import { logout } from '../../store/actions';

class OrderCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        const now = moment();
        let cutoffTime = orderArr ? orderArr.cutoff_time : now;
        let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
        const utcOffset = cutoffTimeMoment.utcOffset();
        cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
        if (now.isBefore(cutoffTimeMoment)) {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {__(orderType)} {__('Order')} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    if (orderType === 'Click & Collect' || orderType === 'table') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Collect from')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted paddLR">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Collect at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted paddLR">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Order placed on')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted paddLR">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format(
                  /* 'ddd DD MMMM YYYY [at] LT' */ 'DD MMM YYYY [at] h:mm a',
                )}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Payment Method')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted paddLR">
              <SmallText color="primary" className="thiner-text">
                {visaEndingDrawText(paymentMetod)}
              </SmallText>
            </IonCol>
          </IonRow>
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Delivery from')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Order placed on')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Delivery at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          {order && order.order_value > 0 ? (
            <IonRow>
              <IonCol size="5">
                <SmallText tag="strong" className="bold">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {paymentMetod}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Drop-off at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Order Placed')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <SmallText tag="strong" className="bold">
                {__('Drop-off Time')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          {order && order.order_value > 0 ? (
            <IonRow>
              <IonCol size="5">
                <SmallText tag="strong" className="bold">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {paymentMetod}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned paddLR">
              <SmallText className="bold">{__('Ordered at')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned paddLR">
              <SmallText color="gray">{order.restaurant_name}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned paddLR">
              <SmallText className="bold">{__('Table number')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned paddLR">
              <SmallText color="gray">{`Table ${order.table_name}`}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned paddLR">
              <SmallText className="bold">{__('Order Placed')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned paddLR">
              <SmallText color="gray">
                {moment(order.created_at).format('ddd DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned paddLR">
              <SmallText className="bold">{__('Payment Method')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned paddLR">
              <SmallText color="gray">{visaEndingDrawText(paymentMetod)}</SmallText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };
  drawOrder = (order, orderCompletePage) => {
    const { __, lastOrder, cards } = this.props;
    const { id } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    console.log({ lastOrder });
    return (
      <>
        <div className="order-status">
          {lastOrder && lastOrder.status
            ? lastOrder.status === 'new'
              ? __('Processing')
              : __(lastOrder.status)
            : ''}
        </div>
        <Spacer size={2} />
        {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
        <Spacer size={1} />
        {/* <Box> */}
        {/* <BoxHeader> */}
        <IonGrid className="okx-box-header-grid">
          <NormalText className="bold">{__('Order Details')}</NormalText>
          {lastOrder.status !== 'new'
            ? this.drawContent(__, order, orderType, basketInstance, orderCompletePage)
            : __('Order is processing')}
        </IonGrid>
        {/* </BoxHeader> */}
        <Spacer size={3} />
        {lastOrder.status === 'new' ? null : (
          <NormalText className="bold">{__('Items Ordered')}</NormalText>
        )}
        {lastOrder.status === 'new' ? null : (
          <OrderContent basketInstance={basketInstance} type="orderHistory" />
        )}
        {lastOrder.status === 'new' ? null : <AmountPaid order={order} cards={cards} />}
        {/* </Box> */}
        {orderType !== 'Click & Collect' ? (
          this.state.isCancel &&
          (order.status === 'PAID' || order.status === 'new') &&
          hasCancelOrder ? (
            <IonButton
              expand="block"
              fill="clear"
              className="link underlined"
              color="tertiary"
              onClick={() => this.handleCancelOrderModal(true)}
            >
              {__('Cancel this order')}
            </IonButton>
          ) : null
        ) : null}
        {this.props.profile.is_guest && (
          <IonButton
            className="order-done-button"
            color="secondary"
            expand="block"
            onClick={() => {
              this.props.dispatch(logout());
            }}
          >
            {__('Done')}
          </IonButton>
        )}
      </>
    );
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { location } = this.props;
    if (location && location.state && location.state.completedOrder) {
      if (this.props.profile.is_guest) this.props.dispatch(logout());
      forwardTo('/dashboard');
      forwardTo('/click-and-collect');
      Basket.reset();
    }
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  render() {
    const { __, lastOrder, profile } = this.props;
    const order = this.props.order || lastOrder;
    const orderCompletePage = true;
    return (
      <Loading>
        <Layout
          blank={profile.is_guest}
          color
          headerTitle={__('Order Completed')}
          backHandler={this.backHandler}
        >
          <div className="absolute-content order-details">
            <div className="scrollable-y">
              {order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;
  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    profile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
