import React from 'react';
import { ElementsConsumer, IdealBankElement } from '@stripe/react-stripe-js';

import IdealBankSection from '../IdealBankSection';
import { IonButton, IonInput, IonLabel } from '@ionic/react';

class IdealBankForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountholderName: '',
    };
  }
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const idealBank = elements.getElement(IdealBankElement);

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = this.state.accountholderName;

    const { error } = await stripe.confirmIdealPayment(this.props.clientSecret, {
      setup_future_usage: 'off_session',
      payment_method: {
        ideal: idealBank,
        billing_details: {
          name: accountholderName,
          email: 'xxx_sepa_dd_succeed_chargeable@example.com',
        },
      },
      return_url: 'https://kauai-nl.web.app/order-completed',
    });

    if (error) {
      // Show error to your customer.
      console.log(error.message);
    }

    // Otherwise the customer will be redirected away from your
    // page to complete the payment with their bank.
  };

  render() {
    const { __ } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="flex-row-wrapper absolute-content">
          <div>
            <div className="form-row">
              <IonLabel>{__('Name')}</IonLabel>
              <IonInput
                onIonChange={(e) => {
                  this.setState({ accountholderName: e.target.value });
                }}
                onIonBlur={(e) => {
                  const nameField = document.querySelector('input[type="name"]:-webkit-autofill');
                  if (nameField) {
                    this.setState({ accountholderName: nameField.value });
                  }
                  this.setState({ accountholderName: e.target.value });
                }}
                name="accountholder-name"
                placeholder="Jenny Rosen"
                required
              />
            </div>
            <br />
            <div className="form-row">
              <IdealBankSection />
            </div>
          </div>
          <div className="flex-min">
            <div tabIndex="-1"></div>
            <IonButton type="submit" color="secondary" expand="block">
              {__('Pay')}
            </IonButton>
          </div>
        </div>
      </form>
    );
  }
}

export default function InjectedIdealBankForm({ clientSecret, dispatch, __, location, profile }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <IdealBankForm
          stripe={stripe}
          elements={elements}
          clientSecret={clientSecret}
          dispatch={dispatch}
          __={__}
          location={location}
          profile={profile}
        />
      )}
    </ElementsConsumer>
  );
}
