import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonBadge,
  IonIcon,
} from '@ionic/react';
import { scan, basket } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import BackButton from '../backButton';
import { forwardTo, getRouteClassName, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';

const StaticHeader = ({ __, history, backHandler, clientProfile, navConfig, showHamburger }) => {
  // const logo_image = clientProfile.logo_image
  //style={{ '--background': isWebConfig() ? '' : `#FFF url(${logo_image}) no-repeat 50% 50%/auto 90%` }
  const currentPath = history.location.pathname;
  const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const pagesWithoutBackButton =
    getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;

  const routeClassName = getRouteClassName(currentPath, navConfig);
  const logoMain = clientProfile?.logo_image || '';

  return (
    <>
      {hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar
            className="primary-toolbar"
            style={{
              backgroundImage: !isWebConfig() ? `url(${logoMain})` : '',
              backgroundSize: 'auto 35px',
            }}
          >
            <IonButtons slot="start">
              {!authPages && (pagesWithoutBackButton || showHamburger) ? (
                <IonMenuButton />
              ) : (
                <>
                  <BackButton backHandler={backHandler} />
                </>
              )}
            </IonButtons>
            <IonButtons color="primary">
              <IonButton
                className="image-button"
                color="primary-shade"
                onClick={() => forwardTo('/dashboard')}
              />
            </IonButtons>
            <IonButtons slot="end">
              {Basket.itemsCount() > 0 ? (
                <IonButton
                  color="dark"
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                  size={24}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        color="primary"
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCount()}
                      </IonBadge>
                      <Icon icon={basket} classname="icon" />
                    </div>
                  </div>
                </IonButton>
              ) : !authPages && !isWebConfig() ? (
                getConfig().appType.hasLoyalty ? (
                  <IonButton
                    color="dark"
                    button
                    clear
                    onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                  >
                    <IonIcon slot="icon-only" icon={scan} />
                  </IonButton>
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="transparent" />
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { clientProfile, navConfig } = store.common;
  return {
    basketUpdated: orders.basketUpdated,
    clientProfile,
    navConfig,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
