import React from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import Basket from '../../lib/basket';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import CheckoutIdeal from '../checkout/CheckoutIdeal';

const { getMobile, setMobile } = Basket;


class IdealPay extends React.Component {

  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }

    this.androidPermissions = new AndroidPermissions();
  }

  componentDidMount() {
    Basket.createOrder("iDealPay");
  }

  render() {
    const {
      __,
      profile,
      dispatch,
      location,
      clientSecret,
    } = this.props;

    return (
      <Loading transparent>
        <Layout
          color="transparent"
          headerTitle={__('Add Payment Card')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <CheckoutIdeal
            clientSecret={clientSecret}
            dispatch={dispatch}
            __={__}
            location={location}
            profile={profile}
          />
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    loading: store.common.loading,
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
  };
};

export default connect(mapStateToProps)(withTranslation(IdealPay));
