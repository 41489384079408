import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
  SmallText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import {
  isDefined,
  checkForDeliveryOption,
  isWebConfig,
  deepCopy,
  isObject,
  parseAllergenData,
  forwardTo,
} from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  passedTheOrder,
  showToast,
} from '../../store/actions';
import { SET_RESTAURANT_PROP, SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime, formatDataForTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';

class OrderToTable extends Component {
  state = {
    selectedRestaurant: null,
    error: '',
    isLocationAllowed: false,
    tableNumber: null,
    showTableNumber: false,
    continueButtonClicked: false,
  };

  componentDidMount() {
    const { restaurants } = this.props;
    if (restaurants.length === 0) this.props.dispatch(getRestaurants());
    Basket.setOrderType('table');
    let shortedRestaurants = restaurants.filter(
      (restaurant) =>
        isDefined(restaurant.can_table_order) &&
        isDefined(restaurant.is_published) &&
        restaurant.can_table_order &&
        restaurant.is_published,
    );
    shortedRestaurants = shortedRestaurants.sort((a, b) => {
      return a.distance - b.distance;
    });
    this.selectRestaurant(shortedRestaurants[0]);
    if (this.props?.location?.state?.selectedRestaurant) {
      const selectedRestaurant = restaurants.find(
        (restaurant) =>
          restaurant.id === this.props?.location?.state?.selectedRestaurant?.restaurant_id,
      );
      this.setState({ selectedRestaurant }, () => {
        Basket.setRestaurant(this.state.selectedRestaurant);
        this.setState({ showTableNumber: true, pickTime: moment(Date.now()).format('HH:mm') });
        Basket.setServicePercentage(0);
        Basket.setCollectionTime(null);
      });
    }
    const deliveryOption = checkForDeliveryOption(Basket.getDeliveryOption(), '/order-to-table');
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(this.props.deliveryOption, '/order-to-table');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  selectRestaurant = (selectedRestaurant) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant, pickTime: moment(Date.now()).format('HH:mm'), showTableNumber: true },
      () => {
        //Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (Basket.getRestaurantServiceCharge()) {
              const service_charge_original = Basket.getRestaurantServiceCharge();
              Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
            } else {
              const defaultServiceCharge = getConfig().general.defaultServiceCharge;
              Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (
        isDefined(store.can_table_order) &&
        isDefined(store.is_published) &&
        store.can_table_order &&
        store.is_published
      ) {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }
        const timeData = formatDataForTime(store, minDT, store.id, false, true, []);
        store.opened =
          timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? 'Closed' : 'Open';
        arrForSelect.push(store);
      }
    });
    return arrForSelect;
  };
  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return menuForReorder[0];
    } else {
      return false;
    }
  };
  chooseMenusForLocation = async () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime, tableNumber } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      Basket.setPassedOrder(true);
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant.id,
        );
        const businessLocationId = choosenRestaurant.business_location_id;
        let cutoffTimeRes = moment().unix();
        Basket.setCutoffTime(cutoffTimeRes);
        Basket.setTableNumber(tableNumber);
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime,
            choosenRestaurant.table_json_time_selector,
          );
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(
                    ikentooMenusForLocation[i].ikentooMenuId,
                    businessLocationId,
                  ),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find(
                    (i) => i.sku === newBasketItem.item.sku,
                  );
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    newBasketItem.item = foundItem;
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false });
                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false });
                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false });
            }
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant
                ? choosenRestaurant.table_json_time_selector
                : [],
            }),
          );
          this.setState({ continueButtonClicked: false });
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
        this.setState({ continueButtonClicked: false });
      } else {
        this.setState({ error: 'Please select pickup time' });
        this.setState({ continueButtonClicked: false });
      }
    });
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  getKitchenStatusColor(status) {
    let color = '';
    switch (status) {
      case 0:
        color = 'success';
        break;
      case 1:
        color = 'warning';
        break;
      case 2:
        color = 'danger';
    }
    return color;
  }
  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store =
      restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );

    if (
      restaurants.length > 0 &&
      showTableNumber &&
      (!selectedRestaurant || !store.is_published || !store.can_table_order)
    ) {
      const shortedRestaurants = restaurants.sort((a, b) => {
        return (
          a.distance - b.distance &&
          a.is_published &&
          b.is_published &&
          a.can_table_order &&
          b.can_table_order
        );
      });
      const filteredRestorants = shortedRestaurants.filter((restaurant) => {
        return restaurant.is_published && restaurant.can_table_order;
      });
      this.selectRestaurant(filteredRestorants[0]);
    }

    return (
      <Layout
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        color="transparent"
      >
        {!showTableNumber && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__('Select another location')}</Title>
              </div>
              <div className="click-collect-locations">
                <IonList>
                  {this.formatDataForSelect(stores).map((store, index) => (
                    <div className="click-collect-items" key={index}>
                      <IonItem
                        disabled={store.opened === 'Closed'}
                        onClick={() => this.setState({ selectedRestaurant: store })}
                        lines="none"
                        className="default-padding"
                      >
                        <IonLabel>
                          <StrongText>{__(store.name)}</StrongText>
                          <SmallText className="block">{__(store.address)}</SmallText>
                          {store.distance && (
                            <div className="bold-description click-collect-small-description">
                              {store.distance < 1
                                ? store.distance.toFixed(1)
                                : store.distance.toFixed(0)}{' '}
                              {__('miles')}
                            </div>
                          )}
                          <SmallText
                            className="bold"
                            color={
                              store?.kitchen_status?.status || store?.kitchen_status?.status === 0
                                ? this.getKitchenStatusColor(store.kitchen_status.status)
                                : ''
                            }
                          >
                            {store?.kitchen_status?.text}
                          </SmallText>
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant.id}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                className="uppercase okx-font-secondary customized-button"
                onClick={() => this.selectRestaurant(selectedRestaurant)}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {showTableNumber && selectedRestaurant && (
          <div className="click-collect-layout">
            <div>
              <Loading transparent>
                <div className="click-collect-wrapper">
                  <div className="click-collect-dialog-header">
                    <Title className="click-collect-title">
                      {deliveryOption ? `${__(deliveryOption.label)}` : ''}
                    </Title>
                    <NormalText className="delivery-option-description">
                      {__(
                        'Start an order to be delivered to your table. This service is available for in store use only.',
                      )}
                    </NormalText>
                    <Spacer />
                  </div>
                </div>
                <div className="click-collect-dialog-content">
                  <IonList className="shadowed-box-light no-padding">
                    <IonItem className="default-padding" key={selectedRestaurant.id} lines="none">
                      <div tabIndex="-1"></div>
                      <IonCheckbox color="secondary" slot="start" checked />
                      <IonLabel className="ion-text-wrap" color="dark">
                        <StrongText>{selectedRestaurant.name}</StrongText>
                        <NormalText className="block">{selectedRestaurant.address}</NormalText>
                        <br />
                        <SmallText
                          className="bold"
                          color={
                            store?.kitchen_status?.status || store?.kitchen_status?.status === 0
                              ? this.getKitchenStatusColor(store.kitchen_status.status)
                              : ''
                          }
                        >
                          {selectedRestaurant?.kitchen_status?.text}
                        </SmallText>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <div className="diffrent_location_div">
                    <IonButton
                      expand="block"
                      fill="clear"
                      color="secondary"
                      className="link underlined"
                      onClick={() =>
                        this.setState({
                          selectedRestaurant: null,
                          showTableNumber: false,
                          tableNumber: null,
                        })
                      }
                    >
                      {__('At a different location?')}
                    </IonButton>
                  </div>
                </div>
                <Spacer size={2} />
                <div className="table-number shadowed-box-light">
                  <StrongText htmlFor="demo-non-form">{__('Table number')}</StrongText>
                  <IonInput
                    className="table-number-input"
                    onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                    value={this.state.tableNumber}
                    type="text"
                    inputmode="tel"
                    placeholder="Enter your table number"
                  />
                  {error ? (
                    <IonItem>
                      <div tabIndex="-1"></div>
                      <FieldError className="field-error" value={__(error)} />
                    </IonItem>
                  ) : null}
                </div>
                <Spacer size={2} />
              </Loading>
              <div className="click-collect-button">
                <IonButton
                  disabled={this.state.tableNumber ? false : true}
                  expand="block"
                  color="secondary"
                  className={`uppercase okx-font-secondary customized-button`}
                  onClick={() => this.chooseMenusForLocation()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        )}
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio
                            color={isWebConfig() ? 'secondary' : 'white'}
                            slot="start"
                            value={ikentooMenuId}
                          />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                className="uppercase okx-font-secondary round-button"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
