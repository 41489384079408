import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject, forwardTo, goBack } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Title } from '../../components/common';
import OrderList from './orderList';
import './index.css';
import Basket from '../../lib/basket';
import { setScrollTop } from '../../store/actions';
import TileOrderList from './tileOrderList';
import { getConfig } from '../../appConfig';

const MENU_DISPLAY_TYPE = {
  SCROLL_MENU_DISPLAY_TYPE: 1,
  TILE_MENU_DISPLAY_TYPE: 2,
  DOUBLE_TILE_MENU_DISPLAY_TYPE: 3,
};

const selectedMenuType = MENU_DISPLAY_TYPE.TILE_MENU_DISPLAY_TYPE;

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      menuRefs: null,
      categoriesPositionTop: [],
      selectedMenu: [],
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }

  setIkentooMenu = (menu) =>
    this.setState(
      {
        ikentooMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      },
    );

  componentDidMount() {
    this.setIkentooMenu();
    if (this.props.location.state) {
      this.setState({ selectedMenu: this.props.location.state.selectedMenu });
    }
  }

  shouldComponentUpdate(nextProps) {
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }

  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
          let name = `${value.name}_${index}`;
          acc[name] = React.createRef();
          return acc;
        }, {})
      : {};
  };

  scrollTo = (name) => this.state.menuRefs[name].current.scrollIntoView();

  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    const positionTop =
      e.target.scrollTop + document.querySelector('.order-categories').clientHeight;
    const { menuRefs } = this.state;
    const positions = Object.keys(menuRefs).map((key) => {
      return menuRefs[key].current.offsetTop;
    });
    let selectCategory = 0;
    positions.forEach((item, i) => {
      if (item <= positionTop) {
        selectCategory = i;
      }
    });
    if (document) {
      const catDiv = document.querySelector('.order-categories-inner');
      if (catDiv && catDiv.childNodes) {
        setTimeout(() => {
          catDiv.childNodes[selectCategory].scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
          });
        }, 500);
      }
    }
    this.props.dispatch(setScrollTop(e.target.scrollTop));
    this.setState({ selectedCategory: selectCategory, scrollTop: e.target.scrollTop });
  };

  backHandler = () => {
    let menuDisplayType = getConfig().menuDisplayType;
    const selectedMenus = this.state.selectedMenu || [];
    if (menuDisplayType !== 1 && selectedMenus.length > 0) {
      const items = this.state.selectedMenu;
      items.pop();
      this.setState({ selectedMenu: items });
    } else {
      goBack();
    }
  };

  selectMenu = (index) => {
    const selectedItem = index;
    const currentCategories = [...this.state.selectedMenu];
    currentCategories.push(selectedItem);
    this.setState({ selectedMenu: currentCategories });
  };

  parseCategories = (data) => {
    const { menuDisplayType, selectedMenu, ikentooMenu } = data;
    let categories;
    if (menuDisplayType !== 1) {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu : [];
      if (selectedMenu?.length > 0) {
        categories = categories.menuEntryGroups[selectedMenu[0]];
        for (let i = 1; i < selectedMenu.length; i++) {
          categories = categories.menuEntry[selectedMenu[i]];
        }
      }
    } else {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    }
    return categories;
  };

  render() {
    const { __, scrollTop } = this.props;
    const { selectedCategory, ikentooMenu, selectedMenu } = this.state;
    let menuDisplayType = getConfig().menuDisplayType;
    let categories = this.parseCategories({ selectedMenu, ikentooMenu, menuDisplayType });

    return (
      <Loading>
        <Layout
          noPadding
          scrollY={false}
          backHandler={this.backHandler}
          showHamburger={selectedMenu?.length === 0}
        >
          {!isDefined(ikentooMenu) ? null : menuDisplayType == 1 ? (
            <div className="segment-holder">
              <div className="order-categories">
                <Title className="margined-bottom">
                  <strong>{__('Menu')}</strong>
                </Title>
                <div className="order-categories-inner">
                  {categories.map((category, index) => {
                    return (
                      <IonButton
                        key={index}
                        size="small"
                        fill="clear"
                        className={
                          selectedCategory === index ? 'category-button active' : 'category-button'
                        }
                        onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                      >
                        {__(category.name)}
                      </IonButton>
                    );
                  })}
                </div>
              </div>
              <div className="order-content">
                <OrderList
                  scrollTopPosition={scrollTop}
                  selectCategoryOnScroll={this.selectCategoryOnScroll}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                />
                {Basket.itemsCountAll() > 0 ? (
                  <div className="view-order-button">
                    <IonButton onClick={() => forwardTo('/order-summary')} expand="block">
                      <IonGrid>
                        <IonRow>
                          <IonCol>{__('View Order')}</IonCol>
                          <IonCol>
                            {Basket.itemsCountAll()}&nbsp;
                            {Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
                          </IonCol>
                          <IonCol>{Basket._getTotal()}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="segment-holder grid-menus">
              <div className="order-categories">
                <TileOrderList
                  selectMenu={(index) => {
                    this.selectMenu(index);
                  }}
                  category={categories}
                  selectedMenu={this.state.selectedMenu}
                ></TileOrderList>
              </div>
              <div className="order-content">
                {Basket.itemsCount() > 0 ? (
                  <div className="view-order-button">
                    <IonButton
                      onClick={() => forwardTo('/order-summary')}
                      expand="block"
                      color="secondary"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol>{__('View Order')}</IonCol>
                          <IonCol>
                            {Basket.itemsCount()}&nbsp;
                            {Basket.itemsCount() === 1 ? __('Item') : __('Items')}
                          </IonCol>
                          <IonCol>{Basket._getTotal()}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth } = state.common;
  const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
  const { scrollTop } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    restaurantsUpdated,
    scrollTop,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
